.nav-menu .active {
  background-color: #EEECF9;
}

.nav-item-menu {
  padding: 6px 0;

  &:hover {
    background-color: #F5F5F5;
  }

  &.active {
    background-color: #EEECF9;
  }
}

.nav-link-menu {
  display: block;
  padding: 12px 8px;
  text-decoration: none;
}

.show-branch {
  display: block;
  padding: 8px 24px;
  text-decoration: none;

  &:hover {
    background-color: #F5F5F5 !important;
  }
}

th {
  background-color: #F9F8FD !important;
  border: none !important;
  vertical-align: middle !important;
}

.page-link {
  border-radius: 4px !important;
  line-height: 20px !important;

  .page-link-arrow {
    font-size: 10px;
  }
}

.page-item.disabled .page-link {
  background-color: #FFFFFF !important;
  color: #DEDEDE !important;
}

.accordion-button {
  border-radius: 4px !important;
}

.accordion-button::after {
  background-image: none !important;
  width: 0 !important;
}

.accordion-button:not(.collapsed) .bi-caret-right-fill {
  transform: rotate(90deg);
  transition: transform 0.2s ease;
}

.accordion-button.collapsed .bi-caret-right-fill {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.accordion-button:not(.collapsed) {
  background-color: #EEECF9 !important;
}

.accordion-body-item:hover {
  &.active {
    background-color: #EEECF9;
  }

  background-color: #F5F5F5;
}

.accordion-button:hover {
  background-color: #F5F5F5 !important;
}

.edit-button {
  background-color: transparent;
}

.business-type {
  border-radius: 4px;
  border: 1px solid #DEDEDE;

  &.active {
    border: 1px solid #5842BE;
    background: #F9F8FD;
  }
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.dropdown-menu-mobile {
  top: 0 !important;
  left: 0 !important;
  transform: translate(0px, 40px) !important;
}

.rdrInRange {
  background-color: #EEECF9;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
  color: #1F3354 !important;
}

.btn-photo {
  opacity: 0;
  transition: .3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 1;
  }
}

.text-line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.text-line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}


.error-heading {
  font-size: 200px;
  line-height: 240px;
}

.input-with-image {
  min-height: 164px;
  max-width: 325px;

  @media (min-width: 745px) {
    min-height: 325px;
  }
}

.custom-btn {
  width: 100% !important;
}

@media (min-width: 431px) {
  .custom-btn {
    width: 181px !important;
  }
}

@media (min-width: 1441px) {
  .menu-container {
    padding: 0 10px;
  }
}

@media (max-width: 1281px) {
  .rdrDefinedRangesWrapper {
    width: 100%;
    border-right: none;
  }

  .rdrStaticRangeLabel {
    text-align: center;
  }
}

@media (min-width: 1281px) {
  .calendar-range-text {
    width: 669px;
    border-left: 1px solid #EEECF9;
  }
}


.square-btn {
  width: 48.45px;
}

.table-thead-width {
  min-width: 200px;
}

.react-dropdown-tree-select {
  .dropdown {
    width: 100%;
  }

  .dropdown .dropdown-trigger {
    font-size: 14px;
    width: 100%;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    padding: 12px;
    transition: transform 0.2s ease;
  }

  .dropdown .dropdown-trigger.arrow {
    text-decoration: none;
  }

  .tag-item {
    margin: 0;
    &:focus-visible,
    &:focus {
      outline: none;
      border: none;
    }
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-right: 12px;
  }

  .tag-item .search {
    font-size: 14px;
    color: #1F3354;
    border-bottom: none;
  }

  .tag-item .tag {
    font-size: 14px;
    color: #5842BE;
    background-color: #EEECF8;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .tag-item .tag-remove {
    font-size: 16px;
    width: 16px;
    color: #5842BE;
    padding: 0;
    margin-left: 8px;
  }

  .placeholder {
    font-size: 14px;
    color: #9D9D9D;
    background-color: transparent;
    opacity: 1;
  }

  .dropdown .dropdown-trigger.arrow.bottom::after,
  .dropdown .dropdown-trigger.arrow.top:after {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 12px;
  }

  .dropdown .dropdown-trigger.arrow.bottom::after,
  .toggle.collapsed:after {
    content: "\F282";
    font-family: "bootstrap-icons";
    font-style: normal;
  }

  .toggle.collapsed:after {
    content: "\F285";
    font-family: "bootstrap-icons";
    font-size: 14px;
    font-style: normal;
  }

  .dropdown .dropdown-trigger.arrow.top:after,
  .toggle.expanded:after {
    content: "\F282";
    font-family: "bootstrap-icons";
    font-size: 14px;
    font-style: normal;
  }

  .dropdown .dropdown-content {
    width: 100%;
    border-radius: 4px;
    padding: 8px 12px;
    border: 1px solid #DEDEDE;
  }

  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
  }

  .node>label {
    margin-left: 0;
    line-height: 22px;
  }

  .dropdown-content .node {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    margin-bottom: 4px;
  }

  .checkbox-item {
    position: relative;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    margin: 0 8px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 1px solid #DDE0E4;
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
    }
  }

  .checkbox-item:checked:before,
  .node.partial .checkbox-item:before {
    background-color: #5842BE;
    border-color: #5842BE;
  }

  .checkbox-item:checked::after {
    content: "\F26E";
    font-family: "bootstrap-icons";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 16px;
  }

  .node.focused,
  .node.tree[aria-selected="true"],
  .node.tree[aria-checked="true"] {
    background-color: #EEECF8;
  }
}

.img-menu {
  width: 110px;
  height: 110px;
}